const { createSlice } = require('@reduxjs/toolkit');

const ordersSlice = createSlice({
    name: 'orders',
    initialState: {
        orders: [
        ],
    },
    reducers: {
        setorders(state, action) {
            state.orders = action.payload;
        }
    },
});

export const { setorders } = ordersSlice.actions;
export default ordersSlice.reducer;
