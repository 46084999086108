import { createSlice } from '@reduxjs/toolkit';

const productSlice = createSlice({
  name: 'product',
  initialState: {
    products: [],
    refresh: false, // Add refresh state
    searchTerm:'',
    searchCategory:''
  },
  reducers: {
    setProducts(state, action) {
      state.products = action.payload;
    },
    toggleproductRefresh(state) {
      state.refresh = !state.refresh; // Toggle refresh state
    },
    setSearchTerm: (state, action) => {
      state.searchTerm = action.payload;
  },
  setSearchCategory(state, action) {
    state.searchCategory = action.payload;
},
  },
});

export const { setProducts, toggleproductRefresh,setSearchTerm ,setSearchCategory } = productSlice.actions;
export default productSlice.reducer;
