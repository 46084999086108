import React, { useEffect, useState } from "react";
import { createRoot } from 'react-dom/client';
import { Provider, useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import App from "./App";
import { store, persistor } from "./store/store";
import PersistProvider from "./store/providers/persist-provider";
import { setProducts } from "./store/slices/product-slice";
import { setReview } from "./store/slices/review-slice";
import 'animate.css';
import 'swiper/swiper-bundle.min.css';
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "./assets/scss/style.scss";
import "./i18n";
import { setUsers } from "./store/slices/users-slice";
import { setorders } from "./store/slices/order-slice";

// Main component to fetch data and render the app
const Main = () => {
  const dispatch = useDispatch();
  const refresh = useSelector((state) => state.user.refresh);
  const refreshreview = useSelector((state) => state.review.refresh);
  const refreshproduct = useSelector((state) => state.product.refresh);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_FOS_CLIENT_API}/product`)
      .then((res) => {
        dispatch(setProducts(res.data));
      })
      .catch((err) => console.log(err));
  }, [dispatch,refreshproduct]);


  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_FOS_CLIENT_API}/reviews`)
      .then((res) => {
        dispatch(setReview(res.data));  
      })
      .catch((err) => console.log(err));
  }, [dispatch,refreshreview]);

  
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_FOS_CLIENT_API}/orders`)
      .then((res) => {
        dispatch(setorders(res.data));
      })
      .catch((err) => console.log(err));
  }, [dispatch]);


  const userId = localStorage.getItem('userId');


  useEffect(() => {
    if (userId) {
      axios
        .get(`${process.env.REACT_APP_FOS_CLIENT_API}/users/${userId}`)
        .then((res) => {
          dispatch(setUsers(res.data));
     
        })
        .catch((err) => console.log(err));
    } else {
      console.log("No userId found in localStorage");
    }
  }, [dispatch, userId,refresh,[]]);

  return (
    <PersistProvider>
      <App />
    </PersistProvider>
  );
};

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <Main />
  </Provider>
);
