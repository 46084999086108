import { Fragment, useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getDiscountPrice } from "../../../helpers/product";
import { deleteFromCart } from "../../../store/slices/cart-slice";



const MenuCart = () => {
  const dispatch = useDispatch();
  const currency = useSelector((state) => state.currency);
  const { cartItems } = useSelector((state) => state.cart);
  const [dropdownActive, setDropdownActive] = useState(false);
  const dropdownRef = useRef(null);
  let cartTotalPrice = 0;

  const calculatePrice = (item) => {
    const discountedPrice = getDiscountPrice(item.price, item.discount);
    const finalProductPrice = (item.price * currency.currencyRate).toFixed(2);
    const finalDiscountedPrice = discountedPrice
      ? (discountedPrice * currency.currencyRate).toFixed(2)
      : null;

    if (discountedPrice != null) {
      cartTotalPrice += finalDiscountedPrice * item.quantity;
    } else {
      cartTotalPrice += finalProductPrice * item.quantity;
    }

    return {
      finalProductPrice,
      finalDiscountedPrice,
    };
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownActive(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const toggleDropdown = (e) => {
    e.stopPropagation(); // Prevent the event from bubbling up
    setDropdownActive((prev) => !prev);
  };

  return (
    <div className="shopping-cart-wrapper">
      <button onClick={toggleDropdown}>
        <i className="pe-7s-shopbag" />
        <span className="count-style">
          {cartItems && cartItems.length ? cartItems.length : 0}
        </span>
      </button>
      <div ref={dropdownRef} className={`shopping-cart-content ${dropdownActive ? "active" : ""}`}>
        {cartItems && cartItems.length > 0 ? (
          <Fragment>
            <ul>
              {cartItems.map((item) => {
                const { finalProductPrice, finalDiscountedPrice } = calculatePrice(item);
                const imageUrl = item.imageUrl[0] || "path/to/default-image.jpg"; // Fallback image

                return (
                  <li className="single-shopping-cart" key={item.cartItemId}>
                    <div className="shopping-cart-img">
                      <Link to={process.env.PUBLIC_URL + "/product/" + item.id}>
                        <img
                          alt=""
                          src={process.env.PUBLIC_URL + imageUrl}
                          className="img-fluid"
                        />
                      </Link>
                    </div>
                    <div className="shopping-cart-title">
                      <h4>
                        <Link to={process.env.PUBLIC_URL + "/product/" + item.id}>
                          {item.name}
                        </Link>
                      </h4>
                      <h6>Qty: {item.quantity}</h6>
                      <span>
                        {finalDiscountedPrice !== null
                          ? currency.currencySymbol + finalDiscountedPrice
                          : currency.currencySymbol + finalProductPrice}
                      </span>
                      {item.selectedProductColor && item.selectedProductSize ? (
                        <div className="cart-item-variation">
                          <span>Color: {item.selectedProductColor}</span>
                          <span>Size: {item.selectedProductSize}</span>
                        </div>
                      ) : null}
                    </div>
                    <div className="shopping-cart-delete">
                      <button onClick={(e) => {
                        e.stopPropagation(); // Prevent the event from bubbling up
                        dispatch(deleteFromCart(item.cartItemId));
                      }}>
                        <i className="fa fa-times-circle" />
                      </button>
                    </div>
                  </li>
                );
              })}
            </ul>
            <div className="shopping-cart-total">
              <h4>
                Total :{" "}
                <span className="shop-total">
                  {currency.currencySymbol + cartTotalPrice.toFixed(2)}
                </span>
              </h4>
            </div>
            <div className="shopping-cart-btn btn-hover text-center">
              <Link className="default-btn" to={process.env.PUBLIC_URL + "/cart"}>
                View Cart
              </Link>
              <Link className="default-btn" to={process.env.PUBLIC_URL + "/checkout"}>
                Checkout
              </Link>
            </div>
          </Fragment>
        ) : (
          <p className="text-center">No items added to cart</p>
        )}
      </div>
    </div>
  );
};

export default MenuCart;
