import React, { Fragment, useEffect, useState } from 'react';
import SEO from '../../components/seo';
import {
  Breadcrumb,
  Table,
  Button,
  Modal,
  Tag,
  Steps,
  Spin,
  Typography,
  Dropdown,
  Menu,
  notification,
} from 'antd';
import {
  ShoppingCartOutlined,
  CarryOutOutlined,
  CheckCircleOutlined,
  SyncOutlined,
  EllipsisOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import LayoutOne from '../../layouts/LayoutOne';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';

const { Step } = Steps;
const { Title } = Typography;
const { confirm } = Modal;

function MyOrders() {
  let { pathname } = useLocation();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [trackingVisible, setTrackingVisible] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const userId = localStorage.getItem('userId');
  const navigate = useNavigate();
  const screens = useBreakpoint();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_FOS_CLIENT_API}/users/${userId}`)
      .then((res) => {
        const sortedOrders = res.data.Orders.sort((a, b) => new Date(b.orderDate) - new Date(a.orderDate));
        setData({ ...res.data, Orders: sortedOrders });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [userId]);

  const showModal = (order) => {
    setSelectedOrder(order);
    setVisible(true);
  };

  const showTrackingModal = (order) => {
    setSelectedOrder(order);
    setTrackingVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleTrackingOk = () => {
    setTrackingVisible(false);
  };

  const handleTrackingCancel = () => {
    setTrackingVisible(false);
  };

  const cancelOrder = (orderId) => {
    confirm({
      title: 'Are you sure you want to cancel this order?',
      icon: <ExclamationCircleOutlined />,
      content: 'This action cannot be undone.',
      onOk() {
        axios
          .put(`${process.env.REACT_APP_FOS_CLIENT_API}/orders/${orderId}`, {
            status: 'cancelled',
          })
          .then((res) => {
            notification.success({
              message: 'Order Cancelled',
              description: 'The order has been successfully cancelled.',
            });
            setData((prevData) => ({
              ...prevData,
              Orders: prevData.Orders.map((order) =>
                order.orderId === orderId
                  ? { ...order, status: 'cancelled' }
                  : order,
              ),
            }));
            setVisible(false);
          })
          .catch((err) => {
            notification.error({
              message: 'Error',
              description: 'Failed to cancel the order. Please try again.',
            });
            console.log(err);
          });
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const columns = [
    {
      title: 'User Email',
      dataIndex: 'ShippingAddress',
      key: 'email',
      render: (text) => text.email,
    },
    {
      title: 'Order Date',
      dataIndex: 'orderDate',
      key: 'orderDate',
      render: (date) => new Date(date).toLocaleString(),
    },
    {
      title: 'Total Amount',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      render: (amount) => `$${amount.toFixed(2)}`,
    },
    {
      title: 'Payment Method',
      dataIndex: 'paymentmtd',
      key: 'paymentmtd',
      render: (paymentMethod) =>
        paymentMethod === 'Cash On Delivery' ? (
          <Tag color="cyan">Pending</Tag>
        ) : (
          <Tag color="green">Success</Tag>
        ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) =>
        screens.lg ? (
          <div>
            <Button
              type="primary"
              onClick={() => showModal(record)}
              style={{ backgroundColor: '#A749FF', borderColor: '#A749FF' }}
            >
              More Details
            </Button>
            <Button
              type="default"
              onClick={() => showTrackingModal(record)}
              style={{
                marginLeft: '10px',
                backgroundColor: '#A749FF',
                color: 'white',
                borderColor: '#A749FF',
              }}
            >
              Track
            </Button>
          </div>
        ) : (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item onClick={() => showModal(record)}>
                  More Details
                </Menu.Item>
                <Menu.Item onClick={() => showTrackingModal(record)}>
                  Track
                </Menu.Item>
              </Menu>
            }
            trigger={['click']}
          >
            <Button icon={<EllipsisOutlined />} />
          </Dropdown>
        ),
    },
  ];

  const getShippingStatusStep = (order) => {
    const status = order.status;
    switch (status) {
      case 'completed':
        return 3;
      case 'OrderAccepted':
        return 1;
      case 'OrderDelivery':
        return 2;
      default:
        return 0;
    }
  };

  const productColumns = [
    {
      title: 'Product Image',
      dataIndex: 'Product',
      key: 'imageUrl',
      render: (product) => (
        <img
          src={product.imageUrl[0]}
          alt={product.name}
          style={{ width: '100px', height: 'auto', borderRadius: '8px' }}
        />
      ),
    },
    {
      title: 'Product Name',
      dataIndex: 'Product',
      key: 'name',
      render: (product) => product.name,
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
    },

    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      render: (price) => `$${price.toFixed(2)}`,
    },
  ];

  return (
    <Fragment>
      <SEO
        titleTemplate="My Orders"
        description="My Orders page showing all orders placed by the user."
      />
      <LayoutOne headerTop="visible">
        <Breadcrumb
          pages={[
            { label: 'Home', path: process.env.PUBLIC_URL + '/shopnow' },
            { label: 'My Orders', path: process.env.PUBLIC_URL + pathname },
          ]}
        />
        <div className="container">
          {loading ? (
            <div style={{ textAlign: 'center', marginTop: '50px' }}>
              <Spin size="large" />
            </div>
          ) : data && data.Orders && data.Orders.length > 0 ? (
            <Table
              style={{ overflowX: 'auto' }}
              columns={columns}
              dataSource={data.Orders}
              rowKey="orderId"
            />
          ) : (
            <div
              className="mb-3"
              style={{ textAlign: 'center', marginTop: '50px' }}
            >
              <Title level={4}>No orders found</Title>
              <Button
                type="primary"
                onClick={() => navigate('/shopnow')}
                style={{
                  marginTop: '20px',
                  backgroundColor: '#A749FF',
                  borderColor: '#A749FF',
                }}
              >
                Shop Now
              </Button>
            </div>
          )}
        </div>
        <Modal
          title="Order Details"
          className="w-sm-100"
          visible={visible}
          onOk={handleOk}
          width="100%"
          onCancel={handleCancel}
        >
          {selectedOrder && (
            <div className="row">
              <div className="col-lg-6">
                <p>
                  <strong>Order Date:</strong>{' '}
                  {new Date(selectedOrder.orderDate).toLocaleString()}
                </p>
                <p>
                  <strong>Total Amount:</strong> $
                  {selectedOrder.totalAmount.toFixed(2)}
                </p>
              </div>
              <div className="col-lg-6">
                <p>
                  <strong>Payment Method:</strong> {selectedOrder.paymentmtd}
                </p>
                <p>
                  <strong>Shipping Address:</strong>{' '}
                  {selectedOrder.ShippingAddress.address},{' '}
                  {selectedOrder.ShippingAddress.city},{' '}
                  {selectedOrder.ShippingAddress.state},{' '}
                  {selectedOrder.ShippingAddress.postalCode}
                </p>
              </div>
              <div className="mt-2">
                <p>
                  <strong>Products:</strong>
                </p>
                <Table
                  style={{ overflowX: 'auto' }}
                  columns={productColumns}
                  dataSource={selectedOrder.OrderItems}
                  pagination={false}
                  rowKey="orderItemId"
                />
              </div>

              <div className="mt-3">
                {/* <div className="row ">
                  <div className="col-12 col-md-6 ">
                    <div className="p-2 bg-light rounded mt-2 w-100">
                      <strong>Shipping Amount:</strong>{' '}
                      {selectedOrder.ShippingAddress.Pincode.shipmentAmount}
                    </div>
                  </div>
                </div> */}

                <div className="row mt-2">
                  <div className="col-12 col-md-3">
                    <div
                      className="btn w-100 bg-light text-dark rounded"
                      style={{ cursor: 'pointer' }}
                    >
                      <strong>Shipping Amount:</strong>{' '}
                      {selectedOrder.ShippingAddress.Pincode.shipmentAmount}
                    </div>
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col-12 col-md-3">
                    <div
                      className="btn w-100 bg-dark text-white rounded"
                      style={{ cursor: 'pointer' }}
                    >
                      Total Amount: $ {selectedOrder.totalAmount}
                    </div>
                  </div>
                </div>

                {/* {selectedOrder.status !== 'cancelled' && (
                  <div className="row mt-2">
                    <div className="col-12 col-md-3">
                      <div
                        className="btn w-100 bg-danger text-white rounded"
                        onClick={() => cancelOrder(selectedOrder.orderId)}
                        style={{ cursor: 'pointer' }}
                      >
                        Cancel Order
                      </div>
                    </div>
                  </div>
                )} */}
              </div>
            </div>
          )}
        </Modal>
        <Modal
          title="Track Order"
          className="w-75"
          visible={trackingVisible}
          onOk={handleTrackingOk}
          onCancel={handleTrackingCancel}
          width={800}
        >
          {selectedOrder && (
            <div>
              <Steps current={getShippingStatusStep(selectedOrder)}>
                <Step
                  title="Ordered"
                  description={'Order has been Ordered'}
                  icon={<ShoppingCartOutlined />}
                />

                <Step
                  title="Order Accepted"
                  icon={<CarryOutOutlined />}
                  description={'Order has been Accepted'}
                />
                <Step
                  title="Shipping"
                  icon={<SyncOutlined spin />}
                  description={
                    selectedOrder.shippingdate
                      ? `Delivery Date: ${selectedOrder.shippingdate}`
                      : ''
                  }
                />
                <Step
                  title="Delivered"
                  icon={<CheckCircleOutlined />}
               
                />
              </Steps>
            </div>
          )}
        </Modal>
      </LayoutOne>
    </Fragment>
  );
}

export default MyOrders;
