import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { Modal } from 'antd';
import MenuCart from './sub-components/MenuCart';
import { useState, useEffect, useRef } from 'react';
import user from './user5.jpg';

const IconGroup = ({ iconWhiteClass }) => {
  const { users } = useSelector((state) => state.user);

  console.log(users, 'profile');

  const [dropdownActive, setDropdownActive] = useState(false);
  const dropdownRef = useRef(null);

  const handleClick = (e) => {
    e.currentTarget.nextSibling.classList.toggle('active');
  };

  const navigate = useNavigate();

  const showLogoutConfirm = () => {
    Modal.confirm({
      title: 'Are you sure you want to log out?',
      onOk: handleLogout,
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const handleLogout = () => {
    localStorage.removeItem('userId');
    localStorage.removeItem('persist:flone');
    navigate(process.env.PUBLIC_URL + '/login-register');
    window.location.reload();
  };

  const triggerMobileMenu = () => {
    const offcanvasMobileMenu = document.querySelector(
      '#offcanvas-mobile-menu',
    );
    offcanvasMobileMenu.classList.add('active');
  };

  const { compareItems } = useSelector((state) => state.compare);
  const { wishlistItems } = useSelector((state) => state.wishlist);
  const { cartItems } = useSelector((state) => state.cart);

  const userId = localStorage.getItem('userId');

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownActive(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [dropdownRef]);

  return (
    <div className={clsx('header-right-wrap', iconWhiteClass)}>
      <div className="same-style header-search d-none d-lg-block">
        {/* <button className="search-active" onClick={e => handleClick(e)}>
          <i className="pe-7s-search" />
        </button> */}
        <div className="search-content">
          <form action="#">
            <input type="text" placeholder="Search" />
            <button className="button-search">
              <i className="pe-7s-search" />
            </button>
          </form>
        </div>
      </div>
      <div
        className="same-style account-setting d-none d-lg-block"
        ref={dropdownRef}
      >
        <button
          className="account-setting-active"
          onClick={() => setDropdownActive(!dropdownActive)}
        >
         {
  users && users.picture ? (
    <div>
      <img
        style={{ borderRadius: '50%', width: '30px' }}
        src={users.picture}
        alt="User"
      />
    </div>
  ) : userId ? (
    <div>
      <img
        style={{ borderRadius: '50%', width: '30px' }}
        src={user}
        alt="User"
      />
    </div>
  ) : (
    <i className="pe-7s-user-female" />
  )
}

        </button>
        <div className={clsx('account-dropdown', { active: dropdownActive })}>
          <ul>
            {userId ? (
              <>
                <li>
                  <Link to={process.env.PUBLIC_URL + '/my-account'}>
                    My Account
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + '/my-orders'}>
                    My Orders
                  </Link>
                </li>
                <li
                  className="text-danger"
                  style={{ cursor: 'pointer' }}
                  onClick={showLogoutConfirm}
                >
                  Log Out
                </li>
              </>
            ) : (
              <>
                <li>
                  <Link to={process.env.PUBLIC_URL + '/login-register'}>
                    Login
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + '/login-register'}>
                    Register
                  </Link>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
      <div className="same-style header-compare">
        <Link to={process.env.PUBLIC_URL + '/compare'}>
          <i className="pe-7s-shuffle" />
          <span className="count-style">
            {compareItems && compareItems.length ? compareItems.length : 0}
          </span>
        </Link>
      </div>
      <div className="same-style header-wishlist">
        <Link to={process.env.PUBLIC_URL + '/wishlist'}>
          <i className="pe-7s-like" />
          <span className="count-style">
            {wishlistItems && wishlistItems.length ? wishlistItems.length : 0}
          </span>
        </Link>
      </div>
      <div className="same-style cart-wrap d-none d-lg-block">
        {/* menu cart */}
        <MenuCart />
      </div>
      <div className="same-style cart-wrap d-block d-lg-none">
        <Link className="icon-cart" to={process.env.PUBLIC_URL + '/cart'}>
          <i className="pe-7s-shopbag" />
          <span className="count-style">
            {cartItems && cartItems.length ? cartItems.length : 0}
          </span>
        </Link>
      </div>
      <div className="same-style mobile-off-canvas d-block d-lg-none">
        <button
          className="mobile-aside-button"
          onClick={() => triggerMobileMenu()}
        >
          <i className="pe-7s-menu" />
        </button>
      </div>
    </div>
  );
};

IconGroup.propTypes = {
  iconWhiteClass: PropTypes.string,
};

export default IconGroup;
