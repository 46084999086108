const { createSlice } = require('@reduxjs/toolkit');

const userSlice = createSlice({
  name: 'user',
  initialState: {
    users: [],
    refresh: false, // Add refresh state
  },
  reducers: {
    setUsers(state, action) {
      state.users = action.payload;
    },
    toggleRefresh(state) {
      state.refresh = !state.refresh; // Toggle refresh state
    }
  },
});

export const { setUsers, toggleRefresh } = userSlice.actions;
export default userSlice.reducer;
