import { createSlice } from '@reduxjs/toolkit';

const reviewSlice = createSlice({
  name: 'review',
  initialState: {
    reviews: [],
    refresh: false, // Add refresh state
  },
  reducers: {
    setReview(state, action) {
      state.reviews = action.payload;
    },
    toggleRefresh(state) {
      state.refresh = !state.refresh; // Toggle refresh state
    }
  },
});

export const { setReview, toggleRefresh } = reviewSlice.actions;
export default reviewSlice.reducer;
